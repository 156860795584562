import Signup from "./components/Signup";
import { Modal, Button, Space } from 'antd';
import {Grid, Card, Spacer, Code } from '@geist-ui/react'
import 'antd/dist/antd.css';
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";



function info() {
  Modal.info({
    title: 'How to use ',
    content: (
      <div>
        <p>1. Enter your valid email to recieve access key</p>
        <p>2. Enter name of Google sheet</p>
        <p>3. Share your Google sheet with <b>anyform@anyform.iam.gserviceaccount.com</b></p>
        <p>Note : Type the correct name of google sheet where you will recieve form responses.</p>
      </div>
    ),
    onOk() {},
  });
}

function App() {
  return (
    <Router>
        <Switch>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
  )
}

function Home() {
  return (
    <div>
      <Grid.Container  xs={20} sm={22} md={8} gap={2} justify="flex-start" height="100px"  style={{
            position: 'absolute', 
            left: '50%', 
            top: '35%',
            transform: 'translate(-46%, -50%)',        
        }}>
        <Grid>
          <Card shadow width="100%" style={{borderRadius:"10px"}} >
            
            <Grid>
              <Spacer h={.5} />
              <Signup />
            </Grid>
            <Grid>
            <Space>
              <Button type="secondary" onClick={info} >Instructions</Button>
            </Space>
            </Grid>
            <span id="checkemail"></span>
          </Card>
        </Grid>
      </Grid.Container>
    </div>
  );
}









export default App;
