import React from 'react';
import {Input, Spacer, Snippet, Button, Grid, Link} from '@geist-ui/react'


export default function Signup() {
    const [emailVal, setemailVal] = React.useState('');
    const [sheetVal, setsheetVal] = React.useState('');
    

    function fetchData() {
        fetch("https://i7akvu.deta.dev/submit",{
            
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"email":emailVal,"sheetname":sheetVal})
            }).then(res => res.json().then(data => {            
                console.log(data);
            })
        );
    }

    function setHtml() {
        document.getElementById("checkemail").innerHTML = "Please Check your email to get access key";
    }

    function mySubmitHandler(event) {
        fetchData();
        console.log(emailVal)
        event.preventDefault();
        setTimeout(setHtml, 1000);
        var form = document.getElementById("email-form")
        form.reset();
    }


    return (
        <div>        
            <form id="email-form" >
                <Input name='Email' placeholder="Email" width="100%" onChange={e => setemailVal(e.target.value)} /> 
                <Spacer h={.5} />
                <Input name='sheet' placeholder="sheet name" width="100%" onChange={e => setsheetVal(e.target.value)}/>
                <Spacer h={.5} />

                {/* <Snippet symbol="" text="anyform@anyform.iam.gserviceaccount.com" width="100%" /> */}
                <Spacer h={.5} />
                <Spacer h={.5} />
                <Button type="secondary" onClick={mySubmitHandler}>Submit</Button>
                <Spacer h={.5} />
                <Button type="secondary">
                <Link href="https://api.notion.com/v1/oauth/authorize?owner=user&client_id=98e70bf5-6f31-485e-b429-ee99e46e597a&redirect_uri=https://anyform.pages.dev&response_type=code" icon>Connect to notion</Link>
                </Button>
                <Spacer h={.5} />
            </form>
            {/* <div>
                <a href="https://api.notion.com/v1/oauth/authorize?owner=user&client_id=98e70bf5-6f31-485e-b429-ee99e46e597a&redirect_uri=https://anyform.pages.dev&response_type=code">Add to Notion</a>
            </div> */}
      </div>
    );
}
